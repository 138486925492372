'use client';

import useChanged from '@/hooks/use-changed';
import classNames from 'classnames';
import {
  CSSProperties,
  ComponentType,
  ForwardedRef,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { BsChevronDown } from 'react-icons/bs';
import Button from './button';

export default forwardRef(function Accordion(
  {
    header,
    isOpen,
    onToggle,
    children,
    iconRotation,
    icon,
    isRounded,
    tabHeadings,
    tabs,
    headerBgColor,
    headerTextColor,
    headerClassName,
    ...props
  }: {
    header: string | ReactNode;
    isOpen?: boolean;
    onToggle?: (isOpen: boolean) => void;
    children?: ReactNode;
    iconRotation?: number;
    icon?: ComponentType<{ className: string; style?: CSSProperties }>;
    isRounded?: boolean;
    tabHeadings?: (string | undefined)[];
    tabs?: (ReactNode | undefined)[];
    headerBgColor?: string;
    headerTextColor?: string;
    headerClassName?: string;
  } & Omit<React.HTMLAttributes<HTMLDivElement>, 'onToggle'>,
  ref?: ForwardedRef<{ updateSize: () => void }>,
) {
  const innerRef = useRef({
    updateSize: (doAnimation = false) => {
      setQueueUpdate((queue) => [...queue, { doAnimation }]);
    },
  });

  if (iconRotation === undefined) {
    iconRotation = 180;
  }
  const [open, setOpen] = useState(isOpen || false);
  const [queueUpdate, setQueueUpdate] = useState<{ doAnimation?: boolean }[]>(
    [],
  );
  const headerRef = useRef<HTMLElement>(null);
  const articleRef = useRef<HTMLElement>(null);
  const Icon: ComponentType<{ className: string; style?: CSSProperties }> =
    icon || BsChevronDown;
  const rounded = isRounded === undefined ? true : isRounded;

  useEffect(() => {
    if (queueUpdate.length && articleRef.current) {
      const newQueue = [...queueUpdate];
      const update = newQueue.shift()!;
      if (open) {
        const start = new Date().getTime();
        articleRef.current.style.height = `${([...articleRef.current.children] as HTMLElement[]).reduce((sum, elem) => sum + (elem.offsetHeight || elem.clientHeight), 0)}px`;

        if (update.doAnimation) {
          let stop = false;
          const animate = () =>
            requestAnimationFrame(() => {
              const y = headerRef.current?.getClientRects()[0].y || 0;
              const viewportHeight = window.innerHeight;
              if (y < 0 || y > viewportHeight) {
                headerRef.current?.scrollIntoView(true);
              }
              const end = new Date().getTime();
              if (!stop) {
                animate();
              }
              if (end - start > 200) {
                stop = true;
              }
            });
          animate();
        }
      } else {
        articleRef.current.style.height = '0px';
      }
      setQueueUpdate(newQueue);
    }
  }, [queueUpdate, articleRef.current, open]);

  useEffect(() => {
    if ((isOpen === true || isOpen === false) && isOpen != open) {
      toggle();
    }
  }, [isOpen]);

  useEffect(() => {
    if (articleRef.current) {
      const observer = new ResizeObserver(function () {
        innerRef.current.updateSize();
      });
      observer.observe(articleRef.current);

      return () => observer.disconnect();
    }
  }, [articleRef.current, innerRef.current]);

  useEffect(() => {
    if (articleRef.current) {
      innerRef.current.updateSize();
    }
  }, [children, articleRef.current]);

  const toggle = () => {
    setOpen(!open);
    setOpen((open) => {
      if (onToggle) {
        onToggle(open);
      }
      if (articleRef.current) {
        if (!open) {
          articleRef.current.style.height = '0px';
          innerRef.current.updateSize(true);
        } else {
          articleRef.current.style.height = `${articleRef.current.scrollHeight}px`;
          innerRef.current.updateSize(true);
        }
      }
      return open;
    });
  };

  useImperativeHandle(ref, () => innerRef.current);

  const [selectedTab, setSelectedTab] = useState(0);
  const selectedTabChanged = useChanged(selectedTab);

  useEffect(() => {
    if (selectedTabChanged) {
      innerRef.current.updateSize();
    }
  }, [selectedTab, selectedTabChanged]);

  return (
    <div {...props}>
      <header
        ref={headerRef}
        className={classNames(
          'flex h-16 cursor-pointer items-center justify-between border border-neutral-300 p-4',
          {
            rounded: rounded,
            'rounded-b-none': open && rounded,
            'bg-neutral-100': !headerBgColor,
          },
          headerClassName,
        )}
        onClick={(e) => {
          const elems = document.querySelectorAll('.tab-button');
          if ([...elems].every((elem) => !elem.contains(e.target as Node))) {
            toggle();
          }
        }}
        style={{
          backgroundColor: headerBgColor || undefined,
          color: headerTextColor || undefined,
        }}
      >
        {typeof header === 'string' ? <span>{header}</span> : header}
        <div className='flex items-center gap-4'>
          {tabHeadings &&
            tabHeadings
              .filter((th) => th)
              .map((heading, index) => (
                <Button
                  key={heading}
                  type='button'
                  className={classNames('border border-sermons-dark', {
                    'font-bold': selectedTab === index,
                  })}
                  buttonType={selectedTab === index ? 'default' : 'flat-blue'}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTab(index);
                  }}
                >
                  {heading}
                </Button>
              ))}
          <Icon
            className='inline-block text-3xl text-sermons-dark transition-transform'
            style={{ transform: open ? `rotate(${iconRotation}deg)` : '' }}
          />
        </div>
      </header>
      <article
        className={classNames(
          'overflow-hidden transition-[height] duration-500',
          {
            'h-0': !open,
          },
        )}
        ref={articleRef}
      >
        <div className='border border-t-0 border-neutral-300 p-4'>
          {tabs &&
            tabs
              .filter((t) => t)
              .map((tab, index) => (
                <article
                  key={index}
                  className={classNames({
                    hidden: selectedTab !== index,
                  })}
                >
                  {tab}
                </article>
              ))}
          {children}
        </div>
      </article>
    </div>
  );
});
