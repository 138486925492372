'use client';

import cx from 'classnames';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

export default function Pagination({
  page: parentPage,
  lastPage,
  pageParam,
  isDynamic,
  setPage: parentSetPage,
}: {
  page: number;
  lastPage: number;
  pageParam?: string;
  isDynamic?: boolean;
  setPage?: (page: number) => void;
}) {
  const [page, setPage] = useState(parentPage);

  useEffect(() => {
    setPage(parentPage);
  }, [parentPage]);

  if (!pageParam) {
    pageParam = 'page';
  }

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const queryStrings: string[] = [];
  for (const [key, value] of searchParams.entries()) {
    if (key !== pageParam) {
      queryStrings.push(`${key}=${encodeURIComponent(value)}`);
    }
  }
  let queryString = `?${queryStrings.join('&')}`;
  if (queryString !== '?') {
    queryString += '&';
  }

  const pageArray = [
    ...Array(
      lastPage > 13 ? (page > 7 && page < lastPage - 6 ? 7 : 10) : lastPage,
    ),
  ].map((_, index) => {
    if (page > 7) {
      if (page <= 13 && lastPage <= 13) {
        return index + 1;
      } else if (page < lastPage - 6) {
        return index + page - 3;
      } else if (lastPage === 8) {
        return index + 1;
      } else {
        return index + lastPage - 9;
      }
    }
    return index + 1;
  });

  return (
    <nav>
      <ol>
        {page > 1 && (
          <li className='inline-block'>
            <Link
              className='ml-1 inline-flex h-6 min-w-6 items-center justify-center border border-neutral-300'
              href={`${pathname}${queryString}${pageParam}=${page - 1}`}
              onClick={(e) => {
                if (isDynamic) {
                  e.preventDefault();
                  setPage(page - 1);
                  parentSetPage?.(page - 1);
                }
              }}
            >
              &laquo;
            </Link>
          </li>
        )}
        {page <= 1 && (
          <li className='ml-1 inline-flex h-6 min-w-6 cursor-default items-center justify-center border border-neutral-300 bg-white'>
            &laquo;
          </li>
        )}
        {page > 7 && lastPage > 13 && (
          <>
            <li className='inline-block'>
              <Link
                className='ml-1 inline-flex h-6 min-w-6 items-center justify-center border border-neutral-300'
                href={`${pathname}${queryString}${pageParam}=1`}
                onClick={(e) => {
                  if (isDynamic) {
                    e.preventDefault();
                    setPage(1);
                    parentSetPage?.(1);
                  }
                }}
              >
                1
              </Link>
            </li>
            <li className='inline-block'>
              <Link
                className='ml-1 inline-flex h-6 min-w-6 items-center justify-center border border-neutral-300'
                href={`${pathname}${queryString}${pageParam}=2`}
                onClick={(e) => {
                  if (isDynamic) {
                    e.preventDefault();
                    setPage(2);
                    parentSetPage?.(2);
                  }
                }}
              >
                2
              </Link>
            </li>
            <li className='ml-1 inline-flex h-6 min-w-6 cursor-default items-center justify-center border border-neutral-300 bg-white'>
              &hellip;
            </li>
          </>
        )}
        {pageArray.map((index) => (
          <li key={index} className='inline-block'>
            <Link
              className={cx(
                'ml-1 inline-flex h-6 min-w-[1.5rem] items-center justify-center border border-neutral-300',
                {
                  'bg-sermons-light text-white': page === index,
                },
              )}
              href={`${pathname}${queryString}${pageParam}=${index}`}
              onClick={(e) => {
                if (isDynamic) {
                  e.preventDefault();
                  setPage(index);
                  parentSetPage?.(index);
                }
              }}
            >
              {index}
            </Link>
          </li>
        ))}
        {page < lastPage - 6 && lastPage > 13 && (
          <>
            <li className='ml-1 inline-flex h-6 min-w-6 cursor-default items-center justify-center border border-neutral-300'>
              &hellip;
            </li>
            <li className='inline-block'>
              <Link
                className='ml-1 inline-flex h-6 min-w-6 items-center justify-center border border-neutral-300'
                href={`${pathname}${queryString}${pageParam}=${lastPage - 1}`}
                onClick={(e) => {
                  if (isDynamic) {
                    e.preventDefault();
                    setPage(lastPage - 1);
                    parentSetPage?.(lastPage - 1);
                  }
                }}
              >
                {lastPage - 1}
              </Link>
            </li>
            <li className='inline-block'>
              <Link
                className='ml-1 inline-flex h-6 min-w-6 items-center justify-center border border-neutral-300'
                href={`${pathname}${queryString}${pageParam}=${lastPage}`}
                onClick={(e) => {
                  if (isDynamic) {
                    e.preventDefault();
                    setPage(lastPage);
                    parentSetPage?.(lastPage);
                  }
                }}
              >
                {lastPage}
              </Link>
            </li>
          </>
        )}
        {page < lastPage && (
          <li className='inline-block'>
            <Link
              className='ml-1 inline-flex h-6 min-w-6 items-center justify-center border border-neutral-300'
              href={`${pathname}${queryString}${pageParam}=${page + 1}`}
              onClick={(e) => {
                if (isDynamic) {
                  e.preventDefault();
                  setPage(page + 1);
                  parentSetPage?.(page + 1);
                }
              }}
            >
              &raquo;
            </Link>
          </li>
        )}
        {page >= lastPage && (
          <li className='ml-1 inline-flex h-6 min-w-6 cursor-default items-center justify-center border border-neutral-300 bg-white'>
            &raquo;
          </li>
        )}
      </ol>
    </nav>
  );
}
