import classNames from 'classnames';
import { forwardRef, LegacyRef, PropsWithChildren } from 'react';

export interface BaseButtonProps {
  buttonType?: 'default' | 'red' | 'flat-white' | 'gray' | 'flat-blue';
  type?: 'button' | 'submit';
  className?: string;
}

function BaseButton(
  {
    buttonType = 'default',
    type = 'button',
    className,
    children,
    onClick,
    disabled,
    ...props
  }: PropsWithChildren<JSX.IntrinsicElements['button'] & BaseButtonProps>,
  ref: LegacyRef<HTMLButtonElement>,
) {
  return (
    <button
      type={type}
      className={classNames(
        'rounded px-2 py-1 hover:brightness-110 active:brightness-110 disabled:opacity-60',
        {
          'bg-gradient-to-b from-sermons-light to-sermons-dark text-white':
            (buttonType || 'default') === 'default',
          'bg-gradient-to-b from-red-500 to-red-700 text-white':
            buttonType === 'red',
          'border border-black bg-white text-black':
            buttonType === 'flat-white',
          'border border-neutral-300 bg-neutral-100 text-black':
            buttonType === 'gray',
          'border border-sermons-dark bg-white text-sermons-dark':
            buttonType === 'flat-blue',
        },
        className,
      )}
      onClick={onClick}
      disabled={disabled}
      ref={ref}
      {...Object.fromEntries<JSX.IntrinsicElements['button']>(
        Object.entries(props).filter(
          ([prop]) => !['children', 'className'].includes(prop),
        ),
      )}
    >
      {children}
    </button>
  );
}

export default forwardRef(BaseButton);
