// src/components/molecules/button.tsx
import classNames from 'classnames';
import Link from 'next/link';
import {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  LegacyRef,
  PropsWithChildren,
} from 'react';
import BaseButton, { BaseButtonProps } from '../atoms/base-button';

const _BUTTON_TAGS = ['a', 'button', 'input'] as const;
type BUTTON_TAG = (typeof _BUTTON_TAGS)[number];

type ButtonPropsMap = {
  [K in BUTTON_TAG]: PropsWithChildren<
    JSX.IntrinsicElements[K] &
      ({
        loading?: boolean;
      } & (K extends 'button' ? { tagType?: K } : { tagType: K }) &
        BaseButtonProps)
  >;
};

type ButtonProps = ButtonPropsMap[keyof ButtonPropsMap];

function Button(props: ButtonProps, ref: ForwardedRef<HTMLElement>) {
  return (
    <>
      {props.tagType === 'button' || !props.tagType ? (
        <BaseButton
          buttonType={props.buttonType || 'default'}
          type={'button' as BaseButtonProps['type']}
          className={classNames(
            {
              loading: props.loading,
            },
            props.className,
          )}
          onClick={props.onClick}
          disabled={props.disabled}
          ref={ref as LegacyRef<HTMLButtonElement>}
          {...props}
        >
          {props.loading && (
            <div className='loading-bar absolute inset-x-0 top-0 h-1 w-full bg-neutral-300'>
              <div className='spinner relative h-1 bg-sermons-dark'></div>
            </div>
          )}
          {props.children}
        </BaseButton>
      ) : props.tagType === 'a' ? (
        <>
          <Link
            href={props.href || ''}
            ref={ref as LegacyRef<HTMLAnchorElement>}
            className={classNames(
              'flex items-center justify-center rounded px-2 py-1',
              {
                'bg-gradient-to-b from-red-500 to-red-800 text-white':
                  props.buttonType === 'red',
                'border border-black bg-white text-black':
                  props.buttonType === 'flat-white',
                'bg-gradient-to-b from-sermons-light to-sermons-dark text-white':
                  (props.buttonType || 'default') === 'default',
                'border border-neutral-300 bg-neutral-100 text-black':
                  props.buttonType === 'gray',
                'border border-sermons-dark bg-white text-sermons-dark':
                  props.buttonType === 'flat-blue',
                loading: props.loading,
              },
              props.className,
            )}
            {...Object.fromEntries<InputHTMLAttributes<HTMLInputElement>>(
              Object.entries(props).filter(
                ([prop]) => !['children', 'className'].includes(prop),
              ),
            )}
          >
            {props.loading && (
              <div className='loading-bar absolute inset-x-0 top-0 h-1 w-full bg-neutral-300'>
                <div className='spinner relative h-1 bg-sermons-dark'></div>
              </div>
            )}
            {props.children}
          </Link>
        </>
      ) : props.tagType === 'input' ? (
        <div className='relative inline-block'>
          <input
            type='file'
            ref={ref as LegacyRef<HTMLInputElement>}
            className={classNames(
              'cursor-pointer rounded border border-black file:!inline-flex file:!cursor-pointer file:!items-center file:!justify-center file:!rounded-l file:!border-none file:!px-2 file:!py-1 file:!content-[""]',
              {
                'file:!bg-gradient-to-b file:!from-red-500 file:!to-red-800 file:!text-white hover:brightness-110 file:active:!from-red-800 file:active:!to-red-800':
                  props.buttonType === 'red',
                'file:!border-r file:!border-solid file:!border-black file:!bg-white file:hover:!border-neutral-500 file:hover:!text-neutral-500 file:active:!border-neutral-300 file:active:!text-neutral-300':
                  props.buttonType === 'flat-white',
                'file:!bg-gradient-to-b file:!from-sermons-light file:!to-sermons-dark file:!text-white file:hover:!brightness-110 file:active:!from-sermons-dark file:active:!to-sermons-dark':
                  (props.buttonType || 'default') === 'default',
                'file:!border-0 file:!border-r file:!border-solid file:!border-neutral-300 file:!bg-neutral-100 file:!text-black':
                  props.buttonType === 'gray',
                'file:!border file:!border-sermons-dark file:!bg-white file:!text-sermons-dark':
                  props.buttonType === 'flat-blue',
              },
              props.className,
            )}
            {...Object.fromEntries<InputHTMLAttributes<HTMLInputElement>>(
              Object.entries(props).filter(
                ([prop]) => !['children', 'className'].includes(prop),
              ),
            )}
          />
          <div className='absolute inset-x-0 top-0 h-1 rounded-t'>
            {props.children}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default forwardRef(Button);
